import React from 'react';
import { Link } from 'react-router-dom';
import './HomePage.css';

function HomePage() {
  return (
    <div className="homepage">
      <section className="hero">
        <h2>Elevate Your Basketball Insights</h2>
        <p>Dive deep into Lebanese basketball with cutting-edge analytics and comprehensive statistics.</p>
      </section>
      <section className="features">
        <h2>The Analytics Suite</h2>
        <div className="feature-grid">
          <div className="feature-card">
            <h3>🔍 Advanced Player Metrics</h3>
            <p>Beyond basic stats: PER, True Shooting %, VORP, and custom analytics for player evaluation.</p>
          </div>
          <div className="feature-card clickable">
            <h3>📈 Team Statistics</h3>
            <p>Comprehensive team stats including advanced metrics like ORTG, DRTG, and possession-based analysis.</p>
            <Link to="/team-stats" className="explore-button">Explore Team Stats</Link>
          </div>
          <div className="feature-card">
            <h3>🏀 Game Prediction Models</h3>
            <p>ML-powered predictive analytics for game outcomes and player performances.</p>
          </div>
          <div className="feature-card">
            <h3>📊 Interactive Visualizations</h3>
            <p>Dynamic charts and heatmaps to visualize complex basketball data and trends.</p>
          </div>
          <div className="feature-card">
            <h3>🔮 Player Development Tracking</h3>
            <p>Long-term analysis of player growth and potential future performance.</p>
          </div>
          <div className="feature-card">
            <h3>🏆 League-wide Insights</h3>
            <p>Comprehensive analytics covering all teams and players in the Lebanese basketball league.</p>
          </div>
          <div className="feature-card clickable">
            <h3>🌍 Global Talent Scouting</h3>
            <p>AI-driven identification of potential Lebanese players competing abroad, expanding the talent pool.</p>
            <Link to="/name-scout" className="explore-button">Explore Name Scout</Link>
          </div>
          {/* New Todo List Card */}
          <div className="feature-card clickable">
            <h3>📝 961bucket's Todo List</h3>
            <p>Check out what's coming next for 961buckets!</p>
            <Link to="/todo" className="explore-button">View Todo List</Link>
          </div>
        </div>
      </section>
      <section className="cta">
        <h2>Ready to Unlock Basketball Insights?</h2>
        <p>Gain a competitive edge with advanced analytics.</p>
        <Link to="/info" className="cta-button">Explore Analytics</Link>
      </section>
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-links">
            <Link to="/todo">Checkout the todo list!</Link>
            <Link to="/contact">Contact Me</Link>
          </div>
          <div className="social-links">
            <a href="https://twitter.com/961buckets" target="_blank" rel="noopener noreferrer">
              <img src="/x.svg" alt="Twitter" className="social-icon" />
            </a>
            <a href="https://www.facebook.com/961buckets" target="_blank" rel="noopener noreferrer">
              <img src="/facebook.svg" alt="Facebook" className="social-icon" />
            </a>
            <a href="https://instagram.com/961buckets" target="_blank" rel="noopener noreferrer">
              <img src="/instagram.svg" alt="Instagram" className="social-icon" />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default HomePage;