import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import './TeamStats.css';

// Add this mapping object at the top of your file, outside the component
const teamLogoMap = {
  'Antranik Beirut': 'logoantranik.png',
  'Riyadi': 'logoriyadi.png',
  'Beirut': 'logobeirut.png',
  'Hoops': 'logohoops.png',
  'Sagesse': 'logosagesse.png',
  'Maristes Champville': 'logomaristes.png',
  'Homentmen': 'hohlogo.png',
  'Mayrouba': 'mayroubalogo.png',
  'Antonine': 'logoantonine.png',
  'N.S.A': 'logonsa.png',
};

function TeamStats() {
  const [teamStats, setTeamStats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSeason, setSelectedSeason] = useState('2023-2024');
  const [selectedGameType, setSelectedGameType] = useState('regular');
  const [statType, setStatType] = useState('basic');
  const [sortKey, setSortKey] = useState('wins');
  const [sortOrder, setSortOrder] = useState('desc');

  useEffect(() => {
    fetchTeamStats();
  }, [selectedSeason, selectedGameType, statType, sortKey, sortOrder]);

  async function fetchTeamStats() {
    setIsLoading(true);
    try {
      const data = statType === 'basic' ? await fetchBasicStats() : await fetchAdvancedStats();
      setTeamStats(data);
    } catch (err) {
      setError(`Failed to fetch ${statType} team stats`);
      console.error(`Error fetching ${statType} team stats:`, err);
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchBasicStats() {
    const { data, error } = await supabase
      .from('team_stats')
      .select('*')
      .eq('season', selectedSeason)
      .eq('is_playoff', selectedGameType === 'playoff')
      .order(sortKey, { ascending: sortOrder === 'asc' });

    if (error) throw error;
    return data;
  }

  async function fetchAdvancedStats() {
    const advancedSeason = selectedSeason.split('-')[0] + '/' + selectedSeason.split('-')[1].slice(-2);
    const { data, error } = await supabase
      .from('advanced_team_stats')
      .select('*')
      .eq('season', advancedSeason)
      .eq('is_playoff', selectedGameType === 'playoff')
      .order(sortKey, { ascending: sortOrder === 'asc' });

    if (error) throw error;
    return data;
  }

  const handleSort = (key) => {
    setSortOrder(key === sortKey && sortOrder === 'desc' ? 'asc' : 'desc');
    setSortKey(key);
  };

  const basicColumns = [
    { key: 'team_name', label: 'Team' },
    { key: 'wins', label: 'W' },
    { key: 'losses', label: 'L' },
    { key: 'win_pct', label: 'Win%' },
    { key: 'ppg', label: 'PPG' },
    { key: 'papg', label: 'PAPG' },
    { key: 'point_diff', label: '+/-' },
    { key: 'rpg', label: 'RPG' },
    { key: 'apg', label: 'APG' },
    { key: 'spg', label: 'SPG' },
    { key: 'bpg', label: 'BPG' },
  ];

  const advancedColumns = [
    { key: 'team_name', label: 'Team' },
    { key: 'games_played', label: 'GP' },
    { key: 'possessions', label: 'POSS' },
    { key: 'ortg', label: 'ORtg' },
    { key: 'drtg', label: 'DRtg' },
    { key: 'points_per_game', label: 'PPG' },
    { key: 'field_goal_percentage', label: 'FG%' },
    { key: 'free_throw_percentage', label: 'FT%' },
    { key: 'offensive_rebounds_per_game', label: 'ORB' },
    { key: 'defensive_rebounds_per_game', label: 'DRB' },
    { key: 'turnovers_per_game', label: 'TOV' },
  ];

  const columns = statType === 'basic' ? basicColumns : advancedColumns;

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="team-stats-container">
      <h2 className="team-stats-title">Team Statistics</h2>
      <div className="team-stats-filters">
        <select 
          className="team-stats-select team-stats-season-select"
          value={selectedSeason} 
          onChange={(e) => setSelectedSeason(e.target.value)}
        >
          <option value="2023-2024">2023-2024</option>
        </select>
        <select 
          className="team-stats-select team-stats-game-type-select"
          value={selectedGameType} 
          onChange={(e) => setSelectedGameType(e.target.value)}
        >
          <option value="regular">Regular Season</option>
          <option value="playoff">Playoffs</option>
        </select>
        <select 
          className="team-stats-select team-stats-stat-type-select"
          value={statType} 
          onChange={(e) => {
            setStatType(e.target.value);
            setSortKey(e.target.value === 'basic' ? 'wins' : 'ortg');
          }}
        >
          <option value="basic">Basic Stats</option>
          <option value="advanced">Advanced Stats</option>
        </select>
      </div>
      <div className="team-stats-table-wrapper">
        <table className="team-stats-table">
          <thead className="team-stats-table-header">
            <tr>
              {columns.map(({ key, label }) => (
                <th 
                  key={key} 
                  className={`team-stats-header-cell team-stats-header-${key}`}
                  onClick={() => key !== 'team_name' && handleSort(key)}
                  data-sort-key={key}
                  data-sort-order={sortKey === key ? sortOrder : ''}
                >
                  {label}
                  {key !== 'team_name' && sortKey === key && (sortOrder === 'asc' ? '▲' : '▼')}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="team-stats-table-body">
            {teamStats.length === 0 ? (
              <tr>
                <td colSpan={columns.length} className="team-stats-no-data">
                  No data available for the selected criteria.
                </td>
              </tr>
            ) : (
              teamStats.map((team, index) => (
                <tr 
                  key={team.team_name} 
                  className="team-stats-row"
                  style={{animationDelay: `${(index + 1) * 0.1}s`}}
                >
                  {columns.map(({ key }) => (
                    <td 
                      key={key} 
                      className={`team-stats-cell team-stats-cell-${key}`}
                      data-stat-type={key}
                    >
                      {key === 'team_name' ? (
                        <>
                          <img 
                            src={`/${teamLogoMap[team.team_name] || 'default-logo.png'}`} 
                            alt={`${team.team_name} logo`} 
                            className="team-logo"
                          />
                          <span className="team-name">{team.team_name}</span>
                        </>
                      ) : key === 'win_pct' || key.includes('percentage')
                        ? `${(team[key] > 1 ? team[key] : team[key] * 100).toFixed(1)}%`
                        : typeof team[key] === 'number'
                        ? team[key].toFixed(1)
                        : team[key]}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TeamStats;