import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import './NameScout.css';

function NameScout() {
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPlayers();
  }, []);

  async function fetchPlayers() {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('ncaa_roster_lebanese_analysis')
        .select('University, Name, Number, Position, Height, Weight, Class, Birthplace, "Potentially Lebanese"');
      if (error) throw error;
     
      setPlayers(data || []);
    } catch (error) {
      console.error('Error fetching players:', error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="name-scout">
      <h1 className="name-scout__title">Name Scout</h1>
      <div className="name-scout__description">
        <p>
          This table displays NCAA players who are potentially of Lebanese descent. 
          I have developed a machine learning model that predicts the probability of a name being Lebanese, 
          based on a dataset of over 20,000 Lebanese names. Some key points:
        </p>
        <ul>
        <li>The model uses probabilistic analysis to identify potential Lebanese names, but it is not 100% accurate. I didn't filter or modify the output of the model in any way so you can see its shortcomings as well.</li>
        <li>Names can be extremely common across different Arab countries, so some non-Lebanese Arab players will for sure be included.</li>
        <li>Currently, the analysis covers only Division 1 NCAA players (5559 players scrapped from all teams across D1) from the 2023/24 season only.</li>
        <li>The goal is to help and support scouts identify and connect with potential Lebanese athletes in the NCAA.</li>
        </ul>
        <p>Future plans:</p>
        <ul>
          <li>The next step is running the model through the Division 2 and Division 3 players.</li>
          <li>Check out the <a href="/todo">todo list</a> for more information. If you'd like this to jump to the top of the list, please upvote these tasks so I can prioritize them!</li>
        </ul>
        <p>Feedback and suggestions to improve this resource are welcome.</p>
      </div>
      <div className="name-scout__table-container">
        <table className="name-scout__table">
          <thead className="name-scout__table-head">
            <tr>
              <th className="name-scout__table-header">Name</th>
              <th className="name-scout__table-header">University</th>
              <th className="name-scout__table-header">Position</th>
              <th className="name-scout__table-header">Height</th>
              <th className="name-scout__table-header">Weight</th>
              <th className="name-scout__table-header">Birthplace</th>
              <th className="name-scout__table-header">Potentially Lebanese</th>
            </tr>
          </thead>
          <tbody className="name-scout__table-body">
            {players.map((player, index) => (
              <tr key={index} className="name-scout__table-row">
                <td className="name-scout__table-cell">{player.Name}</td>
                <td className="name-scout__table-cell">{player.University}</td>
                <td className="name-scout__table-cell">{player.Position}</td>
                <td className="name-scout__table-cell">{player.Height}</td>
                <td className="name-scout__table-cell">{player.Weight}</td>
                <td className="name-scout__table-cell">{player.Birthplace}</td>
                <td className="name-scout__table-cell">{player["Potentially Lebanese"]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {loading && <p className="name-scout__loading">Loading players...</p>}
    </div>
  );
}

export default NameScout;