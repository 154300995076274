import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './InfoDirectory.css';

function InfoDirectory() {
  const [showScrollTop, setShowScrollTop] = useState(false);

  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScrollTop && window.pageYOffset > 300) {
        setShowScrollTop(true);
      } else if (showScrollTop && window.pageYOffset <= 300) {
        setShowScrollTop(false);
      }
    };

    window.addEventListener('scroll', checkScrollTop);
    return () => window.removeEventListener('scroll', checkScrollTop);
  }, [showScrollTop]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="info-directory-wrapper">
      <div className="info-directory-container">
        <h1 className="info-directory-title">Welcome to 961 Buckets Information Directory (this is a WIP filled with slop for now, not final)</h1>
        
        <nav className="section-nav">
          <ul>
            <li><a href="#glossary" onClick={(e) => { e.preventDefault(); scrollToSection('glossary'); }}>Glossary</a></li>
            <li><a href="#about" onClick={(e) => { e.preventDefault(); scrollToSection('about'); }}>About Me</a></li>
            <li><a href="#player-stats" onClick={(e) => { e.preventDefault(); scrollToSection('player-stats'); }}>Player Statistics</a></li>
            <li><a href="#team-stats" onClick={(e) => { e.preventDefault(); scrollToSection('team-stats'); }}>Team Statistics</a></li>
            <li><a href="#contact" onClick={(e) => { e.preventDefault(); scrollToSection('contact'); }}>Contact Me</a></li>
          </ul>
        </nav>

        <article id="glossary" className="info-directory-content glossary-section">
          <h2>Basketball Analytics Glossary</h2>
          <p>Basketball analytics uses many specialized terms. Here's a small and very incomplete guide to the metrics and terms you might encounter, I'm working on extended articles and detailed breakdowns.</p>
          <dl className="info-directory-glossary">
            <div className="glossary-item">
              <dt>PER (Player Efficiency Rating)</dt>
              <dd>A measure of per-minute production standardized so that the league average is 15.</dd>
            </div>
            <div className="glossary-item">
              <dt>TS% (True Shooting Percentage)</dt>
              <dd>A measure of shooting efficiency that takes into account field goals, 3-point field goals, and free throws.</dd>
            </div>
            <div className="glossary-item">
              <dt>USG% (Usage Rate)</dt>
              <dd>An estimate of the percentage of team plays used by a player while they're on the floor.</dd>
            </div>
            <div className="glossary-item">
              <dt>ORTG (Offensive Rating)</dt>
              <dd>Points produced per 100 possessions.</dd>
            </div>
            <div className="glossary-item">
              <dt>DRTG (Defensive Rating)</dt>
              <dd>Points allowed per 100 possessions.</dd>
            </div>
            <div className="glossary-item">
              <dt>VORP (Value Over Replacement Player)</dt>
              <dd>A box score estimate of the points per 100 team possessions that a player contributed above a replacement-level player.</dd>
            </div>
            <div className="glossary-item">
              <dt>PIE (Player Impact Estimate)</dt>
              <dd>A measure of a player's overall statistical contribution to a game.</dd>
            </div>
            <div className="glossary-item">
              <dt>Pace</dt>
              <dd>An estimate of the number of possessions per 48 minutes by a team.</dd>
            </div>
            <div className="glossary-item">
              <dt>eFG% (Effective Field Goal Percentage)</dt>
              <dd>A field goal percentage that adjusts for the fact that 3-point field goals are worth more than 2-point field goals.</dd>
            </div>
            <div className="glossary-item">
              <dt>AST% (Assist Percentage)</dt>
              <dd>An estimate of the percentage of teammate field goals a player assisted while on the floor.</dd>
            </div>
            <div className="glossary-item">
              <dt>TOV% (Turnover Percentage)</dt>
              <dd>An estimate of turnovers per 100 plays.</dd>
            </div>
            <div className="glossary-item">
              <dt>ORB% (Offensive Rebound Percentage)</dt>
              <dd>An estimate of the percentage of available offensive rebounds a player grabbed while on the floor.</dd>
            </div>
            <div className="glossary-item">
              <dt>DRB% (Defensive Rebound Percentage)</dt>
              <dd>An estimate of the percentage of available defensive rebounds a player grabbed while on the floor.</dd>
            </div>
            <div className="glossary-item">
              <dt>BLK% (Block Percentage)</dt>
              <dd>An estimate of the percentage of opponent two-point field goal attempts blocked by the player while on the floor.</dd>
            </div>
            <div className="glossary-item">
              <dt>STL% (Steal Percentage)</dt>
              <dd>An estimate of the percentage of opponent possessions that end with a steal by the player while on the floor.</dd>
            </div>
            <div className="glossary-item">
              <dt>WS (Win Shares)</dt>
              <dd>An estimate of the number of wins contributed by a player.</dd>
            </div>
            <div className="glossary-item">
              <dt>BPM (Box Plus/Minus)</dt>
              <dd>A box score estimate of the points per 100 possessions a player contributed above a league-average player, translated to an average team.</dd>
            </div>
            <div className="glossary-item">
              <dt>Net Rating</dt>
              <dd>The difference between a team's offensive and defensive ratings.</dd>
            </div>
            <div className="glossary-item">
              <dt>PIR (Performance Index Rating)</dt>
              <dd>A sum of positive stats minus negative stats used in some European leagues.</dd>
            </div>
            <div className="glossary-item">
              <dt>Four Factors</dt>
              <dd>The four most important factors in winning: shooting (eFG%), turnovers (TOV%), rebounding (ORB%), and free throws (FT/FGA).</dd>
            </div>
          </dl>
          <p>This comprehensive glossary will help you navigate the advanced statistics and metrics used throughout our site. As you explore player and team stats, refer back to these definitions to gain a deeper understanding of the game.</p>
        </article>

        <article id="about" className="info-directory-content">
          <h2>About Me</h2>
          <p>Welcome to 961 Buckets. My mission is to revolutionize how we understand and appreciate the game in Lebanon. Through this platform, I aim to bring you in-depth insights, comprehensive statistics, and a fresh perspective on the sport we all love.</p>
          <Link to="/about" className="info-directory-link">Learn More Here</Link>
        </article>

        <article id="player-stats" className="info-directory-content">
          <h2>Player Statistics</h2>
          <p>Dive deep into the world of player performance with our extensive database of statistics. Whether you're a fan, coach, or analyst, our player stats section offers a wealth of information to satisfy your curiosity and inform your decisions.</p>
          <Link to="/player-stats" className="info-directory-link">Explore Player Stats</Link>
        </article>

        <article id="team-stats" className="info-directory-content">
          <h2>Team Statistics</h2>
          <p>Understanding team dynamics is crucial to appreciating the full picture of basketball. Our team statistics section provides a comprehensive look at how each squad in the league is performing.</p>
          <p>Examine offensive and defensive ratings, pace of play, rebounding percentages, and more. With our tools, you can analyze how teams match up against each other and identify strengths and weaknesses. Whether you're preparing for your fantasy league or just want to impress your friends with in-depth knowledge, our team stats have got you covered.</p>
          <Link to="/team-stats" className="info-directory-link">Explore Team Stats</Link>
        </article>

        <article id="contact" className="info-directory-content">
          <h2>Contact Me</h2>
          <p>Your feedback and inquiries are invaluable to me. Whether you have questions about the numbers, suggestions for new features, or just want to discuss Lebanese basketball, I'm all ears.</p>
          <p>Feel free to reach out for any reason. Your input helps make 961buckets better and ensures we're providing the basketball community with the insights and tools they need. Let's grow this platform together and take Lebanese basketball analysis to new heights.</p>
          <Link to="/contact" className="info-directory-link">Get in Touch</Link>
        </article>

        {showScrollTop && (
          <div className="scroll-to-top" onClick={scrollToTop}>
            <span className="visually-hidden">Scroll to top</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default InfoDirectory;