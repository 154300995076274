import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { supabase } from '../supabaseClient';
import './PlayerStats.css';

const teamLogoMap = {
  'Antranik Beirut': 'logoantranik.png',
  'Riyadi': 'logoriyadi.png',
  'Beirut': 'logobeirut.png',
  'Hoops': 'logohoops.png',
  'Sagesse': 'logosagesse.png',
  'Maristes Champville': 'logomaristes.png',
  'Homentmen': 'hohlogo.png',
  'Mayrouba': 'mayroubalogo.png',
  'Antonine': 'logoantonine.png',
  'N.S.A': 'logonsa.png',
};

const basicColumns = [
  { key: 'player_name', label: 'Player', sortable: true },
  { key: 'team_logo', label: 'Team', sortable: false },
  { key: 'team_name', label: 'Team Name', sortable: true },
  { key: 'games_played', label: 'GP', sortable: true },
  { key: 'minutes_per_game', label: 'MPG', sortable: true },
  { key: 'points_per_game', label: 'PPG', sortable: true },
  { key: 'rebounds_per_game', label: 'RPG', sortable: true },
  { key: 'assists_per_game', label: 'APG', sortable: true },
  { key: 'steals_per_game', label: 'SPG', sortable: true },
  { key: 'blocks_per_game', label: 'BPG', sortable: true },
  { key: 'turnovers_per_game', label: 'TOV', sortable: true },
  { key: 'field_goal_percentage', label: 'FG%', sortable: true, isPercentage: true },
  { key: 'three_point_percentage', label: '3P%', sortable: true, isPercentage: true },
  { key: 'free_throw_percentage', label: 'FT%', sortable: true, isPercentage: true },
];

const advancedColumns = [
  { key: 'player_name', label: 'Player', sortable: true },
  { key: 'team_logo', label: 'Team', sortable: false },
  { key: 'team_name', label: 'Team Name', sortable: true },
  { key: 'games_played', label: 'GP', sortable: true },
  { key: 'minutes_per_game', label: 'MPG', sortable: true },
  { key: 'effective_field_goal_percentage', label: 'eFG%', sortable: true, isPercentage: true },
  { key: 'assist_to_turnover_ratio', label: 'AST/TO', sortable: true },
  { key: 'stocks', label: 'Stocks', sortable: true },
  { key: 'points_per_minute', label: 'PPM', sortable: true },
];

function PlayerStats() {
  const [playerStats, setPlayerStats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [season, setSeason] = useState('2023/24');
  const [gameType, setGameType] = useState('regular');
  const [sortConfig, setSortConfig] = useState({ key: 'points_per_game', direction: 'desc' });
  const [statType, setStatType] = useState('basic');
  const [currentPage, setCurrentPage] = useState(1);
  const [playersPerPage] = useState(20);
  const [totalPlayers, setTotalPlayers] = useState(0);

  const seasons = useMemo(() => ['2023/24'], []);

  const columns = statType === 'basic' ? basicColumns : advancedColumns;

  const fetchPlayerStats = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const { data, error, count } = await supabase
        .from(statType === 'basic' ? 'player_stats' : 'advanced_player_stats')
        .select('*', { count: 'exact' })
        .eq('season', season)
        .eq('is_playoff', gameType === 'playoff')
        .order(sortConfig.key, { ascending: sortConfig.direction === 'asc' })
        .range((currentPage - 1) * playersPerPage, currentPage * playersPerPage - 1);

      if (error) throw error;
      setPlayerStats(data);
      setTotalPlayers(count);
    } catch (err) {
      setError('Failed to fetch player stats');
      console.error('Error fetching player stats:', err);
    } finally {
      setIsLoading(false);
    }
  }, [season, gameType, sortConfig, statType, currentPage, playersPerPage]);

  useEffect(() => {
    fetchPlayerStats();
  }, [fetchPlayerStats]);

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'desc' ? 'asc' : 'desc',
    }));
  };

  const formatValue = (key, value) => {
    if (value === null || value === undefined) return '-';
    
    const column = columns.find(col => col.key === key);
    if (column?.isPercentage) {
      return `${value.toFixed(1)}%`;
    }
    if (typeof value === 'number') {
      return value.toFixed(1);
    }
    return value;
  };

  const renderSortIndicator = (columnKey) => {
    if (sortConfig.key === columnKey) {
      return <span className="sort-indicator">{sortConfig.direction === 'asc' ? ' ▲' : ' ▼'}</span>;
    }
    return null;
  };

  const totalPages = Math.ceil(totalPlayers / playersPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  if (isLoading) return <div className="player-stats-loading">Loading player statistics...</div>;
  if (error) return <div className="player-stats-error">Error: {error}</div>;

  return (
    <div className="player-stats-container">
      <h2 className="player-stats-title">Player Statistics</h2>
      <div className="player-stats-filters">
        <select 
          className="player-stats-select"
          value={season} 
          onChange={(e) => setSeason(e.target.value)}
        >
          {seasons.map((s) => (
            <option key={s} value={s}>{s}</option>
          ))}
        </select>
        <select 
          className="player-stats-select"
          value={gameType} 
          onChange={(e) => setGameType(e.target.value)}
        >
          <option value="regular">Regular Season</option>
          <option value="playoff">Playoffs</option>
        </select>
        <select 
          className="player-stats-select"
          value={statType} 
          onChange={(e) => setStatType(e.target.value)}
        >
          <option value="basic">Basic Stats</option>
          <option value="advanced">Advanced Stats</option>
        </select>
      </div>
      <div className="player-stats-table-wrapper">
        <table className="player-stats-table">
          <thead>
            <tr>
              {columns.map(({ key, label, sortable }) => (
                <th 
                  key={key} 
                  onClick={() => sortable && handleSort(key)}
                  className={`player-stats-header player-stats-cell-${key} ${sortable ? 'sortable' : ''}`}
                  title={label}
                >
                  {label}
                  {renderSortIndicator(key)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {playerStats.map((player, index) => (
              <tr key={player.id || index} className="player-stats-row">
                {columns.map(({ key }) => (
                  <td 
                    key={key} 
                    className={`player-stats-cell player-stats-cell-${key}`}
                    data-stat-type={key}
                  >
                    {key === 'team_logo' ? (
                      <img 
                        src={`/${teamLogoMap[player.team_name] || 'default-logo.png'}`} 
                        alt={`${player.team_name} logo`} 
                        className="team-logo"
                      />
                    ) : (
                      formatValue(key, player[key])
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <button 
          onClick={() => handlePageChange(currentPage - 1)} 
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>{`Page ${currentPage} of ${totalPages}`}</span>
        <button 
          onClick={() => handlePageChange(currentPage + 1)} 
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default PlayerStats;