import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import './Todo.css';

function Todo() {
  const [tasks, setTasks] = useState([]);
  const [upvotedTasks, setUpvotedTasks] = useState([]);

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    const { data, error } = await supabase
      .from('tasks')
      .select('*')
      .order('upvotes', { ascending: false }); // Order by upvotes in descending order
    if (error) {
      console.error('Error fetching tasks:', error.message);
    } else {
      setTasks(data);
    }
  };

  const handleUpvote = async (taskId) => {
    if (upvotedTasks.includes(taskId)) return;

    // Fetch the current upvotes
    const { data: currentData, error: fetchError } = await supabase
      .from('tasks')
      .select('upvotes')
      .eq('id', taskId)
      .single();

    if (fetchError) {
      console.error('Error fetching current upvotes:', fetchError.message);
      return;
    }

    const newUpvotes = currentData.upvotes + 1;

    // Update the upvotes
    const { data, error } = await supabase
      .from('tasks')
      .update({ upvotes: newUpvotes })
      .eq('id', taskId)
      .select();

    if (error) {
      console.error('Error upvoting task:', error.message);
    } else {
      setTasks(tasks.map(task => 
        task.id === taskId ? { ...task, upvotes: data[0].upvotes } : task
      ));
      setUpvotedTasks([...upvotedTasks, taskId]);
    }
  };

  return (
    <div className="todo">
      <h1 className="todo__title">My todo list for 961buckets:</h1>
      <div className="todo__description">
        <p>Here are my tasks and improvements that are planned, if you'd like an item to jump to the top of the line, please feel free to upvote it!</p>
        <ul className="todo__list">
          {tasks.map(task => (
            <li key={task.id} className="todo__item">
              <span className="todo__text">{task.text}</span>
              <button 
                className="todo__button" 
                onClick={() => handleUpvote(task.id)} 
                disabled={upvotedTasks.includes(task.id)}
              >
                Upvote
              </button> 
              <span className="todo__upvotes">({task.upvotes})</span>
            </li>
          ))}
        </ul>
        <p>If you have any suggestions or would like to prioritize a task, please let me know!</p>
      </div>
    </div>
  );
}

export default Todo;