import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about">
      <h1>About 961buckets</h1>
      <p>
        Welcome to 961buckets—a place for comprehensive basketball stats and advanced analysis 
        focused on Lebanese basketball.
      </p>
      <h2>Our Story</h2>
      <p>
        961buckets started in July 2024, fueled by a love for basketball and a desire to bring 
        advanced analytics to the Lebanese basketball community. My goal is to be the go-to resource 
        for players, coaches, and fans who want data-driven insights to better understand the game.
      </p>
      <h2>The Founder</h2>
      <p>
        I'm a machine learning engineer with experience in modeling and statistical analysis, but 
        basketball is my true passion. With 961buckets, I'm blending my skills in data science with 
        my love for the game to provide fresh insights into Lebanese basketball.
      </p>
      <p>
        This project is as much a learning journey for me as it is a resource for you. While I'm using 
        what I already know, I'm also constantly learning, experimenting, and growing in basketball 
        analytics. I'm working towards becoming a basketball analyst down the road, so I'm getting certified now, and try my best to keep up 
        with the latest research trends in the basketball.
      </p>
      <p>
        961buckets isn't just a website—it's a reflection of my own learning curve. I'll be sharing the 
        latest research, applying it to the Lebanese league, and I will always be open to discussing it with fellow basketball fans. 
        Whether it's exploring new papers, reviewing interesting books, or debating strategies, you'll find 
        it here and on some social media channel. Join the conversation—I'm excited to connect with other 
        basketball enthusiasts as we learn and grow together!
      </p>
      <h2>Contact Us</h2>
      <p>
        Have questions, suggestions, or just want to chat about hoops? I'd love to hear from you!
        <br />
        Email: 961buckets@gmail.com
        <br />
        Our social media:
        <br />
        <a href="https://twitter.com/961buckets" target="_blank" rel="noopener noreferrer">
          <img src="/x.svg" alt="X (Twitter)" style={{width: '20px', height: '20px', verticalAlign: 'middle', marginRight: '5px'}} />
          X (Twitter): @961buckets
        </a>
        <br />
        <a href="https://instagram.com/961buckets" target="_blank" rel="noopener noreferrer">
          <img src="/instagram.svg" alt="Instagram" style={{width: '20px', height: '20px', verticalAlign: 'middle', marginRight: '5px'}} />
          Instagram: @961buckets
        </a>
        <br />
        <a href="https://www.facebook.com/961buckets" target="_blank" rel="noopener noreferrer">
          <img src="/facebook.svg" alt="Facebook" style={{width: '20px', height: '20px', verticalAlign: 'middle', marginRight: '5px'}} />
          Facebook: 961 Buckets
        </a>
      </p>
    </div>
  );
};

export default About;