import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const navItems = [
    { path: '/', label: 'Home' },
    { path: '/team-stats', label: 'Team Stats' },
    { path: '/player-stats', label: 'Player Stats' },
    { path: '/about', label: 'About' },
  ];

  return (
    <header className="header">
      <div className="header__container">
        <Link to="/" className="header__logo">
        <img src="/logo233.png" alt="961 Buckets Logo" style={{ width: '70px', height: 'auto' }} />
          <span>961buckets</span>
        </Link>
        
        <nav className={`header__nav ${isMenuOpen ? 'header__nav--open' : ''}`}>
          {navItems.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              className={`header__nav-item ${location.pathname === item.path ? 'header__nav-item--active' : ''}`}
            >
              {item.label}
            </Link>
          ))}
        </nav>
        
        <button 
          className={`header__menu-toggle ${isMenuOpen ? 'header__menu-toggle--open' : ''}`}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </header>
  );
};

export default Header;