import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import HomePage from './components/HomePage';
import TeamStats from './components/TeamStats';
import About from './components/About';
import PlayerStats from './components/PlayerStats';
import InfoDirectory from './components/InfoDirectory';
import NameScout from './components/NameScout';
import Todo from './components/Todo';
import ContactPage from './components/ContactPage'; 

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <main className="App-main">
          <AppContent />
        </main>
        <footer className="App-footer">
          <p>&copy; 2024 961buckets.com. All rights reserved.</p>
        </footer>
      </div>
    </Router>
  );
}

function AppContent() {
  const location = useLocation();

  useEffect(() => {
    window.gtag('config', 'G-YBFWBKD962', {
      page_path: location.pathname + location.search
    });
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/team-stats" element={<TeamStats />} />
      <Route path="/about" element={<About />} />
      <Route path="/player-stats" element={<PlayerStats />} />
      <Route path="/info" element={<InfoDirectory />} />
      <Route path="/name-scout" element={<NameScout />} />
      <Route path="/todo" element={<Todo />} />
      <Route path="/contact" element={<ContactPage />} /> {/* New route for ContactPage */}
    </Routes>
  );
}

export default App;