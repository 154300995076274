import React from 'react';
import './ContactPage.css';

function ContactPage() {
  return (
    <div className="contact-page">
      <h1>Contact Me</h1>
      <p>I'd love to hear from you! Reach out through social media or email:</p>
      
      <div className="social-links">
        <a href="https://www.facebook.com/961buckets" target="_blank" rel="noopener noreferrer" className="social-link">
          <img src="/facebook.svg" alt="Facebook" />
          Facebook
        </a>
        <a href="https://www.instagram.com/961buckets" target="_blank" rel="noopener noreferrer" className="social-link">
          <img src="/instagram.svg" alt="Instagram" />
          Instagram
        </a>
        <a href="https://twitter.com/961buckets" target="_blank" rel="noopener noreferrer" className="social-link">
          <img src="/x.svg" alt="Twitter" />
          Twitter
        </a>
      </div>

      <div className="email-option">
        <p>Or send me an email:</p>
        <a href="mailto:961buckets@gmail.com" className="email-link">961buckets@gmail.com</a>
      </div>
    </div>
  );
}

export default ContactPage;